<template>
    <contentCardIndex >
        <template slot="cardContent">
                <v-row align="center">
                    <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                        
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}" 
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                            data-cy="search-input"
                        ></alternativeTextField2>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col   cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                        <v-row justify="end">
                           
                            <primaryButton data-cy="export-btn" :props="exportBtnData"></primaryButton>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mt-md-0">
                    <v-col class="mx-0 px-0">
						<v-card class="index-card mx-0 px-0" flat color="transparent" >
                            <v-card-title class="body-1 font-weight-bold px-3 white--text">
                                <v-col class="px-0">
                                    <v-row class="primary py-1 ">
                                        <v-col class="py-1 pb-0 text-sm-start text-center">
                                            <span>Subtotal: {{subtotal}}</span>
                                        </v-col>
                                        <v-col class="py-1 pb-0 text-sm-start text-center">
                                            <span>IVA: {{iva}}</span>
                                        </v-col>
                                        <v-col class="py-1 pb-0 text-sm-start text-center">
                                            <span>Total: {{total}}</span>
                                        </v-col>
                                        <v-col class="py-1 pb-0 text-sm-start text-center">
                                            <span>Saldo: {{balance}}</span>
                                        </v-col>
                                       
                                    </v-row>
                                    
                                </v-col>
                            </v-card-title>
                            <v-card-text class="px-0">
                                <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler">
                        
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>

                    
                </v-row>
                
        </template>

        
    
    </contentCardIndex>

        
</template>
<script>
import Utils from '../../../helpers/Utils'
export default {
    data(){
		return {
            subtotal: 0,
            iva: 0,
            total: 0,
            balance: 0,
			search: '',
            selectedID: '',
			actions: [

                
				{
					name: 'Detalles',
					icon: {
						color: 'primary',
						icon: 'mdi-information-outline',
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'banks.edit',
                    dataCy: "details-link"
				},
                
			],
			tableProps: {
				headers: [
                    {
						text: 'Proveedor',
						align: 'left break-words',
						value: 'supplier_name',
						class: 'table-b-border black--text'
					},
                     {
						text: 'Subtotal',
						align: 'left break-words',
						value: 'subtotal',
						class: 'table-b-border black--text'
					},
                     {
						text: 'IVA',
						align: 'left break-words',
						value: 'iva',
						class: 'table-b-border black--text'
					},
                     {
						text: 'Total',
						align: 'left break-words',
						value: 'total',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Pagado',
						align: 'left break-words',
						value: 'paid',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Saldo',
						align: 'left break-words',
						value: 'debt',
						class: 'table-b-border black--text'
					},
					
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
			
			
            exportBtnData: {
                text: "Exportar",
                icon: "",
				to: "",
				block: false,
                click: ()=>{this.export()}
            },
            

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			
		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.exportBtnData.block = true;
                
            }
            else{
                this.exportBtnData.block = false;
                
            }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {
			this.tableProps.loading = true;
            this.subtotal = this.iva = this.total = this.balance =  0 ;
			// call api
			this.$api.report.debtsToPay()
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.reverse().map((x) => {
                        this.subtotal = this.subtotal + parseFloat(x.subtotal);
                        this.iva = this.iva + parseFloat(x.iva);
                        this.total = this.total + parseFloat(x.total);
                        this.balance = this.balance + parseFloat(x.debt);

						x['actions'] = JSON.parse(JSON.stringify(this.actions))
						
                        x.actions[0].action = { name: 'DebtsToPayDetails', params: { id: x.supplier_id } }

						// x['parameters'] = { id: x.id, name: x.alias_name, index: i};

                        x.subtotal = Utils.currencyFormat(x.subtotal, "");
                        x.total = Utils.currencyFormat(x.total, "");
                        x.paid = Utils.currencyFormat(x.paid, "");
                        x.debt = Utils.currencyFormat(x.debt, "");
                        x.iva = Utils.currencyFormat(x.iva, "");
                       
						return x;
					});

                    this.subtotal = Utils.currencyFormat(this.subtotal, "");
                    this.iva = Utils.currencyFormat(this.iva, "");
                    this.total = Utils.currencyFormat(this.total, "");
                    this.balance = Utils.currencyFormat(this.balance, "");
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},



		export()
        {
            this.exportBtnData.loading = true;
            this.$api.report.exportDebtsToPay()
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `debts_to_pay.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                })
            ;
        },

    }
}
</script>